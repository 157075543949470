<template>
  <div :class="[{ 'border rounded': hasBorder }, { expanded: isOpen }]">
    <div
      class="flex items-center pt-5 px-4 cursor-pointer toggle-section__header"
      :class="{ 'border-b': isOpen && $slots.content && hasHeaderBottomBorder }"
      @click.stop="toggleOpen"
    >
      <span v-if="chevronPosition === 'left'" class="pb-5 chevron">
        <icon-button>
          <dropdown-chevron
            :is-open="isOpen"
            :is-default-position="false"
            class="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-grey-icon icon-base"
          />
        </icon-button>
      </span>
      <div
        class="pb-5 flex w-full justify-between toggle-section__title"
        :class="headerClass"
      >
        <slot name="titleSection" />
        <slot name="advanced" />
        <span v-if="chevronPosition === 'right'" class="chevron">
          <icon-button>
            <dropdown-chevron
              :is-open="isOpen"
              :is-default-position="false"
              class="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-grey-icon icon-base"
            />
          </icon-button>
        </span>
      </div>
    </div>
    <div
      v-if="isOpen && $slots.content"
      :class="{ 'p-5': hasPadding, 'bg-ghost-white': contentGray }"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType } from "vue";
import IconButton from "@/components/ui/buttons/IconButton.vue";
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  section: {
    type: String,
    required: true
  },
  hasPadding: {
    type: Boolean,
    default: true
  },
  hasBorder: {
    type: Boolean,
    default: true
  },
  chevronPosition: {
    type: String as PropType<"left" | "right" | "">,
    default: "left"
  },
  hasHeaderBottomBorder: {
    type: Boolean,
    default: true
  },
  contentGray: {
    type: Boolean,
    default: false
  },
  headerClass: {
    type: String,
    default: "items-center"
  }
});
const emit = defineEmits<{
  (eventName: "toggle", key: string): void;
}>();
const toggleOpen = () => {
  emit("toggle", props.section);
};
</script>
